
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner-container {
    @media screen and (max-width: 1023px) {
        padding-top: 4vh;
        padding-bottom: 4vh;
    }
}
.main-banner {
    margin: 0 auto;
    background-color: #e7e6e4;
    position: relative;
    @media screen and (max-width: 730px) {
        min-height: calc(100vh - 60px);
    }
    .content {
        z-index: 4;
        position: relative;
        text-align: left;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        height: fit-content;
        padding: 20px 25px;
        background-color: white;
        border-radius: 10px;
        width: 100%;
        padding-bottom: 40px;

        h1 {
            font-weight: bold;
            font-size: 20px;
            color: $insurance-super-saver-assault;
            @include lg {
                font-size: 30px;
            }
        }
        p {
        }
        .selling-box {
            border-radius: 10px;
            z-index: 4;
            margin-top: 30px;
            // width: 80%;
        }

        @include lg {
            // margin-right: -100px;
            padding: 50px 40px;
            margin-bottom: 0;
            width: 70%;
            margin-top: 40px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .image {
        position: absolute !important;
        right: 0%;
        bottom: 0;
        height: 300px;
        width: 600px;
       
        max-width: unset;

        @media screen and (max-width: 1023px) {
            img {
                min-height: unset !important;
                min-width: unset !important;
                width: auto !important;
                height: 300px !important;
                margin-right: 0 !important;
                max-width: unset !important;
            }
        }

        @include lg {
            top: 0;
            max-width: 753px;
            min-height: calc(100vh - 76px);
            img {
                min-width: unset !important;
                width: auto !important;
                min-height: calc(100vh - 76px) !important;
                margin-right: 0 !important;
                max-width: 100% !important;
            }
        }
    }
    .city-best {
        color: $insurance-super-saver-assault;
        @apply capitalize mt-6;
    }
    @include lg {
        min-height: calc(100vh - 76px);
        // min-height: 620px;
        padding: 5vh 0;
    }
}
